<template>
    <HeaderComponent/>
    <SectionContent/>
    <FooterComponent/>
</template>

<script>

export default {
    name: 'App',
    components: {},
    mounted() {
        let lang = localStorage.getItem('lang')
        if (lang) {
            this.$i18n.locale = lang
        }
    }
}
</script>

<style lang="scss">
@import '~@/scss/main.scss';
.full-screen {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10001;
    display: flex;
    justify-content: center;
    align-items: center;

    .img {
        width: 80%;
        max-height: 80%;
    }
    .close {
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 50px;
        width: 25px;
        height: 24px;
        background: transparent;

        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 0;
            width: 24px;
            height: 2px;
            background: white;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}
</style>
