<template>
    <section class="our-culture">
        <div class="wrap row align-center">
            <div class="our-culture__wrap our-culture-left-line">
                <h2 class="our-culture__title">{{ title }}</h2>
            </div>
            <div class="our-culture__wrap">
                <h3 class="our-culture__description">{{ description }}</h3>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "OurCulture",
    props:{
        data:{
            type: [Object,Array],
            default: () => { return {} }
        }
    },
    computed:{
        title(){
            return  this.data?.title[this.$i18n.locale]
        },
        description(){
            return  this.data?.description[this.$i18n.locale]
        }
    }
}
</script>

<style scoped>

</style>