<template>
    <section class="contact" id="contact">
        <div class="wrap contact__wrap">
            <h2 class="contact__title">{{ title }}</h2>
            <div class="contact__info">
                <ul class="contact__list">
                    <li class="contact__item row" v-for="(c,i) in contacts" :key="i">
                        <div class="contact__item-wrap contact__icon icon">
                            <img class="icon__img" :src="require('@/assets/contacts/'+ c.icon +'.svg')" alt="">
                            <div class="icon__badge"></div>
                        </div>
                        <div class="contact__item-wrap contact__item-info">
                            <h3 class="contact__item-title">{{ c.title }}</h3>
                            <p class="contact__item-information">{{ c.more_info }}</p>
                            <p class="contact__item-information">{{ c.contact }}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "ContactUs",
    props:{
        data:{
            type: [Object,Array],
            default: () => { return {} }
        }
    },
    computed:{
        title(){
            return  this.data?.title[this.$i18n.locale]
        },
        contacts(){
            return this.data?.contacts
        }
    }
}
</script>

<style scoped>

</style>