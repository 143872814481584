<template>
    <div>
        <section class="info wrap">
            <ul class="info__list">
                <li class="info__item" v-for="(item, i) in list" :key="i">
                    <h4 class="color-white">{{ item.title }}</h4>
                    <p class="h4 color-white text-center">{{ item.description[lang] }}</p>
                </li>
            </ul>
        </section>
    </div>
</template>

<script>
export default {
    name: "infoComponent",
    props:{
        data:{
            type: [Object,Array],
            default: () => { return {} },
        },
    },
    data(){
        return {
            list: [
                {
                    title: '280K +',
                    description: {
                        en: "Assets",
                        es: "Assets",
                        pt: "Assets",
                    }
                },
                {
                    title: '75 +',
                    description: {
                        en: "Strategic Partners",
                        es: "Strategic Partners",
                        pt: "Strategic Partners",
                    }
                },
                {
                    title: '48+',
                    description: {
                        en: "TB  Data Processed Monthly",
                        es: "TB  Data Processed Monthly",
                        pt: "TB  Data Processed Monthly",
                    }
                }
            ]
        }
    },
    components:{
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        partners(){
            return this.data
        },
    }
}
</script>

<style scoped lang="scss">
.info {
    border-radius: 24px;
    background: linear-gradient(90deg, #136ED2 0%, #228ADC 50%, #4CCFFF 100%);
    padding: 35px 0;
    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        &:not(:last-child) {
            border-right: solid 1px white;
        }
    }
    .color-white {
        color: white;
    }
    .text-center {
        text-align: center;
    }
}
@media all and (max-width: 730px) {
    .info {
        padding: 0 60px;
        &__list {
            grid-template-columns: 1fr;
        }
        &__item {
            padding: 20px 0;
            &:not(:last-child) {
                border-right: none;
                border-bottom: solid 1px white;
            }
        }
    }
}
</style>