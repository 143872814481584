<template>
    <section class="head-section">
        <!--   pagination     -->
        <div class="my-pagination"></div>
        <!--    swiper container    -->
        <div class="head-section-swiper-wrap">
            <swiper
                class="head-section-swiper"
                v-bind="slideOptions"
            >
                <swiper-slide class="head-section-swiper__item"
                              v-for="(slide,i) in sliderList"
                              :key="i"
                              :style="{background: `black url(' ${require('@/assets/products/' + slide.img.title + '.png')} ') no-repeat ${slide.img.position}`}"
                              :data-hash="i"
                              :class="'slide-shadow-' + slide.img.shadow"
                >
                    <div class="wrap">
                        <div class="head-section-swiper__item-slide">
                            <div class="head-section__main">
                                <h1 class="head-section__title">{{ slide.title[lang] }}</h1>
                                <h3 class="head-section__description">{{ slide.description[lang] }}</h3>
                            </div>
                            <div class="head-section__button">
                                <a v-if="slide.buttons.btn_product"
                                   class="button button-transparent mr-20"
                                   :href="require('@/assets/download/' + slide.buttons.btn_product.url + '.png')"
                                   download
                                >
                                    {{ $t('Pro {name} Sales Sheet' , {name: slide.buttons.btn_product.title } ) }}
                                </a>
                                <a v-if="slide.buttons.btn"
                                   class="button button-default"
                                   href="https://prometheuspro.zohobookings.com/#/customer/meetings"
                                   target="_blank"
                                >
                                    {{ $t('buttons.' + slide.buttons.btn.title) }}
                                </a>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </section>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import 'swiper/css/autoplay'

import { Pagination , Autoplay, EffectFade} from "swiper";
export default {
    name: "HeadSection",
    props:{
        "data":{
            type: [Object,Array],
            default: () => { return {} }
        }
    },
    data(){
        return {
            slideOptions:{
                direction: 'vertical',
                slidesPerView: 1,
                spaceBetween: 0,
                loop:true,
                observe:true,
                observerParents:true,
                observeSlideChildren:true,
                hashNavigation:{
                  watchState: true,
                },
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                },
                effect:"fade",
                speed: 1000,
                allowTouchMove:false,
                pagination: {
                    el: '.my-pagination',
                    bulletClass: 'my-bullets',
                    bulletActiveClass:'my-active-bullet',
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<div class="' + className + '">' + '0' + (index + 1) + "</div>";
                    },
                },
                modules:[Pagination, Autoplay, EffectFade],
            },
        }
    },
    components:{
        Swiper,
        SwiperSlide,
    },
    computed:{
        lang(){
            return this.$i18n.locale
        },
        title(){
            return  this.data?.title[this.lang]
        },
        description(){
            return  this.data?.description[this.lang]
        },
        sliderList(){
            return this.data?.slider
        }
    }
}
</script>

<style scoped>

</style>