<template>
    <section class="our-products">
        <swiper class="our-products__swiper" v-bind="swiperOptions">
            <swiper-slide class="our-products__slide" v-for="(partner,i) in partners" :key="i">
                <a class="our-products__item" @click="img = partner.img">
                    <img :src="require('@/assets/products/'+partner.img+'.png')" alt="arrow">
                </a>
            </swiper-slide>
        </swiper>
        <teleport to="body" v-if="img">
            <div class="full-screen">
                <button class="close" @click="img = ''"></button>
                <img class="img" :src="require('@/assets/products/'+img+'.png')" alt="arrow">
            </div>
        </teleport>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay'

import { Autoplay, } from "swiper";
export default {
    name: "OurProductsImg",
    props:{
        data:{
            type: [Object,Array],
            default: () => { return {} }
        },
    },
    data(){
        return {
            img: '',
            swiperOptions:{
                centeredSlides:true,
                slidesPerView: 1.5,
                loopedSlides: 4.5,
                spaceBetween: 10,
                autoplay: {
                    delay: 2500,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false,
                },
                breakpoints:{
                    320:{
                        slidesPerView: 1.1
                    },
                    500:{
                        slidesPerView: 1.4
                    },
                    690:{
                        slidesPerView: 1.4
                    },
                    845:{
                        slidesPerView: 1.8
                    },
                    941:{
                        slidesPerView: 2
                    },
                    1491:{
                        slidesPerView: 3.2
                    },
                    1645:{
                        slidesPerView: 3.5
                    },
                    1870:{
                        slidesPerView: 4
                    }
                },
                loop: true,
                observe: true,
                observeParents: true,
                observeSlideChildren: true,
                speed:1000,
                modules:[Autoplay]
            }
        }
    },
    components:{
        Swiper,
        SwiperSlide,
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        partners(){
            return this.data
        },
    }
}
</script>

<style scoped lang="scss">
.our-products {
    padding: 30px 0;
    &__item {
        display: flex;
        background: white;
        height: 300px;
        width: 465px;
        padding: 25px 15px;
        border-radius: 16px;
        gap: 15px;
        cursor: pointer;
        transition: .2s;
        &:hover {
            transform: scale(2);
            z-index: 10;
            transition: .2s;
        }
    }
    &__wrap-img {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__text {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__description {
        //width: 160px;
    }
    &__img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
    }
    &__slide {
        //transition: .2s;
        &:hover {
            //transform: scale(2);
            z-index: 10;
            //transition: .2s;
        }
    }
}
@media all and (max-width: 1140px) {
    .our-products {
        &__item {
            width: 100%;
            &:hover {
                transform: scale(1);
            }
        }
    }
}
@media all and (max-width: 690px) {
    .our-products {
        &__item {
            width: 100%;
        }
    }
}
@media all and (max-width: 350px) {
    .our-products {
        &__item {
            width: 100%;
            height: 250px;
        }
    }
}
</style>