<template>
    <section class="dealer-form">
        <div class="wrap">
            <h2 class="dealer-form__title">{{ title[lang] }}</h2>
            <div class="dealer-form__nav">
                <div class="dealer-form__nav-item dealer-form__nav-1" :class="[errors?.tab1?.length && 'dealer-form__nav-item--error', tab === 0 && 'dealer-form__nav-item--active']">
                    <span class="dealer-form__nav-badge" @click="setTab(0)">1</span>
                </div>
                <div class="dealer-form__nav-item dealer-form__nav-2" :class="[errors?.tab2?.length && 'dealer-form__nav-item--error', tab === 1 && 'dealer-form__nav-item--active']">
                    <span class="dealer-form__nav-badge" @click="setTab(1)">2</span>
                </div>
            </div>
            <form class="dealer-form__body" id="contactForm" name="contact_form" @submit.prevent="sendEmail" ref="formRef">
                <ul class="dealer-form__block">
                    <li class="dealer-form__item" v-for="(element, i) in form" :key="i">
                        <template v-for="(el, j) in element" :key="j">
                            <label class="dealer-form__item__label" v-show="tab === i">
                                <h3 class="dealer-form__item__title">
                                    <span class="dealer-form__item__title-badge" v-if="el.required" >*</span>
                                    {{ el.label }}:
                                </h3>
                                <div class="dealer-form__item__input-block">
                                    <input
                                        class="dealer-form__item__input"
                                        :type="el.type"
                                        :name="el.id"
                                        :required="el.required"
                                        v-model="params[el.id]"
                                    >
                                </div>
                            </label>
                        </template>
                    </li>
                </ul>
                <div class="dealer-form__checkbox">
                    <div>
                        <input type="checkbox" name="privacy_policy" v-model="params['privacy_policy']" required>
                    </div>
                    <p class="dealer-form__checkbox__text">
                        Yes, I would like to receive marketing communications regarding Prometheus products, service, newsletters and events.
                        <br>
                        Your information will be used in accordance with Prometheus’s
                        <a href="https://app.termly.io/document/privacy-policy/db1331f8-4fd5-4b42-8c99-70d89bcc5b37"
                           target="_blank"
                        >
                            Privacy Policy.
                        </a>
                    </p>
                </div>
                <div class="dealer-form__footer">
                    <button type="submit"
                            class="button button-default"
                            :class="[(!inProgress || !valid) && 'button-disabled']"
                            :disabled="!inProgress || !valid"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";

const def_params = {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    company: '',
    company_website: '',
    city: '',
    additional_company_details: '',
    privacy_policy: false
};

export default {
    name: "dealerForm",
    data() {
        return {
            inProgress: true,
            toast: useToast(),
            tabs: [0],
            tab: 0,
            formRef: null,
            errors: {},
            title: {
                en: "Become a Dealer",
                es: "Become a Dealer",
                pt: "Become a Dealer",
            },
            form: [
                [
                    {
                        id: 'first_name',
                        label: 'First name',
                        type: 'text',
                        required: true,
                    },
                    {
                        id: 'last_name',
                        label: 'Last Name',
                        type: 'text',
                        required: true,
                    },
                    {
                        id: 'phone',
                        label: 'Phone',
                        type: 'tel',
                        required: true,
                    },
                    {
                        id: 'email',
                        label: 'E-main',
                        type: 'email',
                        required: true,
                    },
                ],
                [
                    {
                        id: 'company',
                        label: 'Company',
                        type: 'text',
                        required: true,
                    },
                    {
                        id: 'company_website',
                        label: 'Company Website',
                        type: 'text',
                        required: true,
                    },
                    {
                        id: 'city',
                        label: 'City',
                        type: 'text',
                        required: true,
                    },
                    {
                        id: 'additional_company_details',
                        label: 'Additional company details',
                        type: 'text',
                    },
                ]
            ],
            params: {...def_params}
        }
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        valid () {
            return this.params['privacy_policy'] && !this.errors?.tab1?.length && !this.errors?.tab2?.length
        }
    },
    watch: {
        params: {
            handler: function () {
                let form = this.$refs.formRef
                let tab1 = this.form[0].map(f => {
                    let field = form.querySelector(`[name="${f.id}"]`);
                    console.log(field.value)
                    return {field: f.id, error: !field.checkValidity()}
                })
                let tab2 = this.form[1].map(f => {
                    let field = form.querySelector(`[name="${f.id}"]`);
                    return {field: f.id, error: !field.checkValidity()}
                })
                this.errors = {
                    tab1: tab1.filter(v => v.error),
                    tab2: tab2.filter(v => v.error)
                }
            },
            deep: true,
        }
    },
    methods: {
        setTab(tab) {
            //if(!this.tabs.includes(tab)) return false;
            this.tab = tab
        },
        async sendEmail() {
            // let s = false;
            // if(s) {
                this.inProgress = false
                // (optional) Wait until recaptcha has been loaded.
                await this.$recaptchaLoaded()
                // Execute reCAPTCHA with action "login".
                const token = await this.$recaptcha('sendEmail')

                // Do stuff with the received token.
                // console.log('recaptcha', token)

                axios({
                    method: 'post',
                    url: "/email.php",
                    data: {...this.params, token},
                })
                .then((resp) => {
                    // console.log('good', resp)
                    if(resp.data.error){
                        console.error(resp.data.message)
                        this.toast.error(resp.data.message)

                        //'<div class="alert alert-danger"> Error! The security token has expired or you are a bot.</div>'
                    } else {
                        console.log(resp.data.message)
                        this.toast.success(resp.data.message)
                        // '<p class="alert alert-success">Thank You! Your message has been successfully sent.</p>';
                        this.params = {...def_params}
                    }
                    this.inProgress = true
                })
                .catch((error) => {
                    console.warn(error)
                    //if(error.response.status == 403)
                    //if(error.response.status == 500)
                    {
                        let message = "Something went wrong, your message could not be sent."
                        this.toast.error(message)
                    // 500:
                    // <p className="alert alert-warning">Something went wrong, your message could not be sent.</p>
                    // 403:
                    // <p className="alert-warning">There was a problem with your submission, please try again.</p>
                    }
                    this.inProgress = true
                })
                // return false;
            // }
        }
    },
    mounted() {

    },
}
</script>

<style scoped lang="scss">
.dealer-form {
    background: white;
    padding: 80px 0 ;
    &__title {
        margin-bottom: 70px;
    }
    &__nav {
        display: flex;
        margin-bottom: 80px;
    }
    &__nav-item {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &:before {
            content: '';
            height: 5px;
            width: 100%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: #E7E7E7;
        }
    }
    &__nav-badge {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        background:  #E7E7E7;
        border-radius: 100%;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: white;
        z-index: 1;
        cursor: pointer;
    }
    &__nav-item--error {
        .dealer-form__nav-badge {
            background:  #fc6d6d;
        }
        &:before {
            background: #fc6d6d;
        }
    }
    &__nav-item--active {
        .dealer-form__nav-badge {
            background:  linear-gradient(90deg, #1068D0 0%, #51E0FA 100%);
        }
        &:before {
            background: #00A2EE;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        //align-items: center;
        max-width: 50%;
        width: 100%;
        margin: 0 auto;
        gap: 20px;
    }
    &__block {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    &__item {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
    .dealer-form__item__label {
        position: relative;
    }
    &__item__title {
        position: absolute;
        background: white;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #0F3A6C;
        top: -10px;
        left: 50px;
    }
    .dealer-form__item__input {
        width: 100%;
        height: 88px;
        border-radius: 8px;
        border: solid 1px #D1D6DD;
        outline: none;
        color: #0F3A6C;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-left: 50px;
        padding-right: 50px;
        &:focus {
            border: solid 1px #2D537F;
            outline: none;
        }
        &:invalid {
            border-color: red;
        }
    }
    &__checkbox {
        display: flex;
        gap: 15px;
        margin-bottom: 40px;
    }
    &__footer {
        display: flex;
        justify-content: center;
    }
}
</style>