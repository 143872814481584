<template>
    <section class="main">
        <HeadSectionMain :data="head"/>
        <OurProductsImg :data="ourPartners"/>
        <template v-for="(product, i) in listProducts" :key="i">
            <productComponent :data="product"
                              :class="[
                                  i%2 && 'reverse',
                                  i === 0 && 'first',
                                  (i === listProducts.length - 1) && 'last'
                              ]"
            />
        </template>
    </section>
</template>

<script>
export default {
    name: 'HomePage',
    data() {
        return {
            jsonFile: ''
        }
    },
    components: {},
    computed:{
        head(){
            return this.jsonFile?.head
        },
        aboutUs(){
            return this.jsonFile?.about_us
        },
        ourVision(){
            return this.jsonFile?.our_vision
        },
        ourCulture(){
            return this.jsonFile?.our_culture
        },
        ourPartners(){
            return this.jsonFile?.our_products
        },
        products(){
            return this.jsonFile?.products
        },
        contact(){
            return this.jsonFile?.contact
        },
        productsList(){
            return this.jsonFile?.products_list
        },
        info(){
            return this.jsonFile?.info
        },
        listProducts() {
            return this.jsonFile?.list_products
        }
    },
    mounted() {
    },
    created() {
        this.jsonFile = require('../../pages/main-page.json')
    }
}
</script>
<style lang="scss" scoped>
.dabble-bg {
    position: relative;
    z-index: 1;
    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 50%;
        top: 0;
        left: 0;
        z-index: -1;
    }
    &:before {
        background: #F0F2F4;
    }
    &:after {
        top: 50%;
        background: #F9F9F9;
    }
}
</style>