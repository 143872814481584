import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/HomePage.vue'
// import ProductPage from '@/views/ProductPage.vue'
// import trackPage from '@/views/trackPage.vue'
// import visionPage from '@/views/visionPage.vue'
// import assetsPage from '@/views/assetsPage.vue'
// import reeferPage from '@/views/reeferPage.vue'
// import cargoPage from '@/views/cargoPage.vue'
// import eldPage from '@/views/eldPage.vue'
// import dealerPage from '@/views/dealerPage.vue'
import ContactsPage from '@/views/ContactsPage.vue'
import AboutPage from '@/views/AboutPage.vue'
// import ContactForm from "@/components/base/ContactForm";

const routes = [
  {
    path: '/contact',
    name: 'Contacts',
    component: ContactsPage
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Main',
    component: HomePage
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach((to) => {
  if(to.name == 'Main' && to.path != '/'){
    return '/'
  }
  return true
})
export default router
