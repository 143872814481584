<template>
    <section class="product-item">
        <div class="wrap product-item__wrap product-item__flex">
            <div class="product-item__img">
                <img :src="require('@/assets/our-product/'+img+'.png')" alt="">
                <div class="product-item__buttons">
                    <ButtonLearnMore v-if="links?.img?.more" :link="links?.img?.more"/>
                    <ButtonBookDemo v-if="links?.img?.demo"/>

                    <ButtonLearnMore v-if="links.more" :link="links.more" class="btn-mob"/>
                    <ButtonBookDemo v-if="links.demo" class="btn-mob"/>
                </div>
            </div>
            <div class="product-item__text">
                <h2 class="text-center">{{ title }}</h2>
                <p class="product-item__description">{{ description }}</p>
                <div class="product-item__slot">
                    <slot name="sensors"></slot>
                </div>
                <div class="product-item__buttons">
                    <ButtonLearnMore v-if="links.more" :link="links.more"/>
                    <ButtonBookDemo v-if="links.demo"/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "productComponent",
    props: {
        data: Object
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        title() {
            return this.data.title[this.lang]
        },
        description() {
            return this.data.description[this.lang]
        },
        img() {
            return this.data.img
        },
        links() {
            return this.data.links
        }
    }
}
</script>

<style scoped lang="scss">
.product-item {
    background: white;
    padding: 30px 0;
    .btn-mob {
        display: none;
    }
    &__flex {
        display: flex;
        gap: 50px;
        //flex-direction: row-reverse;
        align-items: center;
    }
    &.first,
    &.last {
        background: white;
    }
    &.reverse {
        .product-item__flex {
            flex-direction: row-reverse;
        }
        .product-item {
            &__img {
                align-items: flex-end;
            }
        }
    }
    &__wrap {
        max-width: calc(100vw - 15%);
    }
    &__img {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 64px;
    }
    &__text {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    &__buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
    }
    img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }
    &__description {
        font-size: 32px;
        font-weight: 500;
    }
}
@media all and(max-width: 1050px) {
    .product-item {
        &__description {
            font-size: 18px;
            line-height: 22px;
            font-weight: 400;
            font-style: normal;
        }
    }
}
@media all and(max-width: 900px) {
    .product-item {
        padding: 40px 0;
        &__wrap {
            max-width: calc(100vw - 15%);
        }
        &__flex {
            flex-direction: column-reverse;
            justify-content: center;
            gap: 40px;
        }
        &.reverse {
            .product-item__flex {
                flex-direction: column-reverse;
                justify-content: center;
            }
            .product-item {
                &__img {
                    align-items: center;
                }
            }
        }
        &__text {
            width: 100%;
            .product-item__buttons {
                display: none;
            }
        }
        &__img {
            width: 100%;
            align-items: center;
        }
        .text-center {
            text-align: center;
        }
        .btn-mob {
            display: flex;
        }
    }
}
</style>