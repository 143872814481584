<template>
    <section class="info-section">
        <div class="wrap">
            <h3 class="info-section__description">{{ description }}</h3>
            <div>
                <a class="button button-default"
                   href="https://prometheuspro.zohobookings.com/#/customer/meetings"
                   target="_blank"
                >
                    {{ $t('buttons.Become a Client') }}
                </a>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "InfoSection",
    props:{
        data:{
            type: [Object,Array],
            default: () => { return {} }
        }
    },
    computed:{
        url(){
            return  this.data?.url
        },
        description(){
            return  this.data?.description[this.$i18n.locale]
        }
    }
}
</script>

<style scoped>

</style>