<template>
    <section class="about-us-section">
        <div class="wrap about-us">
            <div class="about-us__wrap">
                <h2 class="about-us__title">
                    {{ title }}
                </h2>
            </div>
            <div class="about-us__wrap">
                <h3 class="about-us__description">
                    {{ description }}
                </h3>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AboutUs",
    props:{
        data:{
            type: [Object,Array],
            default: () => { return {} }
        }
    },
    computed:{
        title(){
            return  this.data?.title[this.$i18n.locale]
        },
        description(){
            return  this.data?.description[this.$i18n.locale]
        }
    }
}
</script>

<style scoped>

</style>