<template>
    <section class="other-products">
        <div class="wrap">
            <div class="other-products__wrap">
                <div class="product_row  column">
                    <div class="other-products__wrap">
                        <h2 class="other-products__title">{{ title }}</h2>
                    </div>
                    <div class="other-products__wrap row justify-end">
                        <h3 class="other-products__description other-products-left-line">{{ description }}</h3>
                    </div>
                </div>
                <div class="product_row row justify-end">
                    <a  class="button button-default transparent border-blue"
                        :href="require('@/assets/download/' + buttons.btn_product.url + '.png')"
                        download
                        v-if="buttons.btn_product"
                    >
                        {{ $t('buttons.Pro {name} Sales Sheet', { name: buttons.btn_product.title }) }}
                    </a>
                    <a  v-else
                        class="button button-default"
                        href="https://prometheuspro.zohobookings.com/#/customer/meetings"
                        target="_blank"
                    >
                        {{ $t('buttons.' + buttons.btn.title ) }}
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "OtherProduct",
    props:{
        data:{
            type: [Object,Array],
            default: () => { return {} }
        }
    },
    computed:{
        title(){
            return  this.data?.title[this.$i18n.locale]
        },
        description(){
            return  this.data?.description[this.$i18n.locale]
        },
        buttons(){
            return this.data?.buttons
        }
    }
}
</script>

<style scoped>

</style>