<template>
    <section class="head-section">
        <video class="head-section__img" :src="video" autoplay muted playsinline  loop="loop" v-if="video" ref="video"></video>
        <img class="head-section__img" :src="require(`@/assets/products/${data.img}.jpg`)" :alt="data.img" v-else>
        <ButtonRequestQuote/>
    </section>
</template>
<script>

export default {
    name: "HeadSectionMain",
    props:{
        "data":{
            type: [Object,Array],
            default: () => { return {} }
        }
    },
    data(){
        return {
        }
    },
    components:{
    },
    computed:{
        roteName() {
            return this.$route?.name
        },
        lang(){
            return this.$i18n.locale
        },
        title(){
            return  this.data?.title[this.lang]
        },
        description(){
            return  this.data?.description[this.lang]
        },
        sliderList(){
            return this.data?.slider
        },
        video() {
            return this.data?.video
        }
    },
    // watch: {
    //     roteName: {
    //         handler: function (nval) {
    //             console.log(nval)
    //             this.play()
    //         },
    //         immutable: true
    //     },
    // },
    methods: {
        play() {
            // if(!this.$refs.video.paused && !this.$refs.video.ended) {
                console.log('play', )
                this.$refs.video.play()
            // }
            // console.log('no')
        }
    },
    mounted() {
        if(this.video) {
            this.play()
        }
        // document.addEventListener("DOMContentLoaded", this.play);
    },
    beforeUnmount() {
        // document.removeEventListener('DOMContentLoaded', this.play)
    }
}
</script>

<style scoped lang="scss">
video::-webkit-media-controls {
    display:none !important;
}
.head-section {
    position: relative;
    height: 100%;
    max-height: calc(80vh - 330px);
    overflow: hidden;
    display: flex;
    &__img {
        width: 100%;
        object-fit: cover;
    }
    .button-default {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}
@media all and (max-width: 650px) {
    .head-section {
        .button-default {
            bottom: 20px;
        }
    }
}
</style>