<template>
    <div class="main">
        <div class="img-wrap" v-if="false">
            <img :src="require('@/assets/quota.png')" alt="">
        </div>
        <quoteForm />
    </div>
</template>

<script>
export default {
    name: "ContactsPage",
    data() {
        return {
            jsonFile: '',
        }
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        head(){
            return this.jsonFile?.head
        },
    },
    mounted() {
        this.jsonFile = require('../../pages/dealer.json');
    }
}
</script>

<style scoped lang="scss">
.img-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: calc(80vh - 330px);
    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: fill;
    }
}
@media all and (max-width: 1300px) {
    .img-wrap {
        height: auto;
    }
}
</style>