<template>
    <section class="product" :style="{background: '#161617' +' '+ 'url('+require('@/assets/products/' + pageName +'.png')+') no-repeat left top / auto 101%' }">
        <div class="product__wrap">
            <div class="product__container">
                <h1 class="product__title">{{title}}</h1>
                <div class="product__description">{{ description }}</div>
                <div class="product__buttons">
                    <a class="button button-transparent"
                       :href="require('@/assets/download/' + buttons.btn_product.url + '.png')"
                       download
                       v-if="buttons.btn_product"
                    >
                        {{ $t('buttons.Pro {name} Sales Sheet', { name: buttons.btn_product.title }) }}
                    </a>
                    <a class="button button-default"
                       href="https://prometheuspro.zohobookings.com/#/customer/meetings"
                       target="_blank"
                       v-if="buttons.btn"
                    >
                        {{ $t('buttons.' + buttons.btn.title ) }}
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "HeadProductSection",
    props:{
        data:{
            type: [Object,Array],
            default: () => { return {} }
        },
        pageName: String
    },
    computed:{
        title(){
            return  this.data?.title[this.$i18n.locale]
        },
        description(){
            return  this.data?.description[this.$i18n.locale]
        },
        buttons(){
            return this.data?.buttons
        }
    }
}
</script>

<style scoped>

</style>