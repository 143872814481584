<template>
    <section class="main">
        <HeadSectionMain :data="head"/>
        <OurProductsImg :data="ourPartners"/>
        <h2 style="text-align: center; margin-bottom: 50px;">{{ title[lang] }}</h2>
        <h3 class="wrap" style="white-space: pre-line; text-align: center; margin-bottom: 50px; padding: 0 20px">
            {{aboutUs.text}}
        </h3>
    </section>
</template>
<script>
export default {
    name: "AbutPage",
    data() {
        return {
            jsonFile: '',
            title: {
                en: "About Us",
                es: "About Us",
                pt: "About Us",
            }
        }
    },
    computed:{
        lang() {
            return this.$i18n?.locale
        },
        head(){
            return this.jsonFile?.head
        },
        ourPartners(){
            return this.jsonFile?.our_products
        },
        aboutUs(){
            return this.jsonFile?.about_us
        },
    },
    created() {
        this.jsonFile = require('../../pages/about-page.json')
    }
}
</script>