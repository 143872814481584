<template>
    <a class="button button-default"
       :href="'/contact'"
    >
        {{ text }}
    </a>
</template>

<script>
export default {
    name: "ButtonBookDemo",
    props: {
        link: String,
    },
    data() {
        return {
            title: {
                en: "Request a Quote",
                es: "Request a Quote",
                pt: "Request a Quote"
            },
        }
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        text() {
            return this.title[this.lang]
        }
    }
}
</script>

<style scoped>

</style>